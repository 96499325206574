<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import type { UcfeCredentials } from "@/types/utils";
import Ucfe from "@/services/Ucfe";
import { isNil } from "lodash";

@Component({})
export default class UcfeBtnValidator extends Vue {
  @Prop(Object) readonly value!: UcfeCredentials;
  @Prop(String) readonly rut!: string;

  loading = false;
  valid: boolean | null = null;

  get disabled() {
    return (
      !this.value.code ||
      !this.value.url ||
      !this.value.password ||
      !this.value.terminal ||
      !this.rut
    );
  }

  get validated() {
    return !isNil(this.valid);
  }

  get sColor() {
    let sValue = "primary";

    if (this.validated) {
      sValue = this.valid ? "success" : "error";
    }

    return sValue;
  }

  async onValidate() {
    if (this.disabled) {
      return;
    }

    this.loading = true;
    this.valid = await Ucfe.validateCredentials(this.rut, this.value);
    this.loading = false;
  }
}
</script>

<template>
  <v-btn
    :disabled="disabled"
    :loading="loading"
    :color="sColor"
    outlined
    small
    @click="onValidate"
  >
    {{ $t("ucfe.validate.credentials") }}
  </v-btn>
</template>
